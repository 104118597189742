/* body {
  padding-top: 120px;
  padding-bottom: 50px;
 } */
.navbar-brand {
  text-transform: none; }

.msg-h1 {
  text-transform: none; }

.fixed-bottom {
  padding-top: 20px; }

.wrapper-class {
  padding: 1rem;
  border: 1px solid #ccc; }

.editor-class {
  background-color: white;
  padding: 1rem;
  border: 1px solid #ccc;
  min-height: 300px; }

.toolbar-class {
  border: 1px solid #ccc; }

.ql-container {
  border: 1px solid #ccc;
  border-radius: 4px; }

.ql-snow .ql-toolbar {
  background-color: #f3f3f3;
  border-bottom: 1px solid #ccc; }

.ql-snow .ql-editor {
  min-height: 200px;
  background-color: #fff; }

.ql-snow .ql-picker {
  color: #333; }

.ql-snow .ql-stroke {
  stroke: #333; }

.ql-snow .ql-fill {
  fill: #333; }

.tabs-container {
  overflow-x: hidden;
  /* Hide horizontal overflow */
  overflow-y: auto;
  /* Enable vertical scrolling if needed */ }
